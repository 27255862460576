<template>
  <div
    :class="(params.orientation === 'vertical') ? 'flex-row' : 'flex-column'"
    id="scroll-div"
    ref="scrollDiv"
    class="scroll-x bg-white"
  >
    <draggable
      :animation="300"
      :class="(params.orientation === 'vertical') ? 'phase-container' : ''"
      handle=".drag-class"
      ghost-class="ghost"
      @start="notDragging = true"
      @end="onDragEnded"
    >
      <div
        v-for="(playground, iterator) in playgrounds"
        v-if="playground.blocks.length > 0"
        :key="uuid(playground)"
        :id="playground.id"
        :ref="playground.id"
        :style="(params.orientation === 'vertical') ? 'min-width: 170px; max-width: calc(100%); width: 100%;' : 'max-width: 100%'"
        :class="(params.orientation === 'vertical') ? phaseWithZIndex : ''"
      >
        <playground
          :id="playground.id"
          :identifier="iterator"
          :playground="playground"
          :schedule="schedule"
          :params="params"
          :date="date"
          :scroll-position="scrollPosition"
          :vertical-scroll="verticalScroll"
          :playgrounds-bookings="playgroundsBookings"
          style="border-left: 1px solid rgba(100, 100, 100, 0.1);"
          ref="playgroundTable"
          @on:schedule-reload="$emit('on:schedule-reload')"
          @on:booking-copy="onBookingCopy"
          @on:booking-move="onBookingMove"
        />
      </div>
    </draggable>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import {putClubPlayground} from "@api/doinsport/services/playground/playground.api";

export default {
  props: {
    playgroundsBookings: {
      type: Array,
      default: () => [],
    },
    schedule: {
      type: Object,
      default: () => {
      },
    },
    params: {
      type: Object,
      default: () => {
      },
    },
    date: {
      type: Object,
      default: () => {
      },
    },
    count: {
      type: Number,
      default: () => 1,
    },
    hours: {
      type: Array,
      default: () => [],
    },
    playgrounds: {
      type: Array,
      default: () => {
      },
    },
    verticalScroll: {
      type: Number,
      default: 0
    },
  },
  watch: {
    date: function () {
      this.loadRedLinePosition();
    }
  },
  data: () => ({
    cssStyle: '',
    dataUpdate: null,
    notDragging: false,
    isDisplayed: false,
    positionRedLine: {top: 0, left: 0, show: true},
    showRedLine: true,
    scrollPosition: 0,
  }),
  components: {
    draggable,
    Playground: () => import('./Playground'),
  },
  computed: {
    phaseWithZIndex() {
      return !this.isDisplayed ? 'phaseWithoutZIndex' : 'phaseWithZIndex';
    }
  },
  methods: {
    uuid(e) {
      if (e.id) return e.id;

      return Math.random()
        .toString(16)
        .slice(2)
        ;
    },
    onBookingCopy(scope) {
      this.$emit('on:booking-copy', scope);
    },
    onBookingMove(scope) {
      this.$emit('on:booking-move', scope);
    },
    onDragEnded($event) {
      const idPlaygroundFrom = $event.clone.id;
      const to = $event.newDraggableIndex;

      putClubPlayground(idPlaygroundFrom, {position: to})
        .then((response) => {
          this.$store.commit('planning/resetBlocksByDate');
        })
        .finally(() => {
          this.notDragging = false;
        })
    },
    getCountPlaygrounds() {
      let count = 0;
      this.playgrounds.forEach(item => {
        if (item) {
          count = count + 2;
        }
      });
      return count;
    },
    getRedLinePosition() {
      switch (this.params.orientation) {
        case "vertical":
          return 'top: ' + this.positionRedLine.top;
        default:
          return 'left: ' + this.positionRedLine.left;
      }
    },
    loadRedLinePosition() {
      const firstTime = this.hours[0];
      const now = this.$moment.utc();

      if (this.$moment.utc(this.$store.getters["planning/getDate"]).format('YYYYMMDD') === now.format('YYYYMMDD')) {
        this.positionRedLine.show = true;
        const minutes = now.diff(firstTime, 'minutes');
        this.positionRedLine.top = ((minutes * this.params.size) + 70) + 'px';
        this.positionRedLine.left = ((2 * minutes * this.params.size) + 80) + 'px';
      } else {
        this.positionRedLine.show = false;
      }
    },
  },
  created() {
    this.loadRedLinePosition();
  },
  mounted() {
    const scroll = this.$refs.scrollDiv
    scroll.addEventListener('scroll', () => {
      this.scrollPosition = this.$refs.scrollDiv.scrollLeft;
    }, false)

    this.$bus.$on('on:drop-menu:display', (display) => {
      this.isDisplayed = display;
    });
  },
  beforeDestroy() {
    try {
      this.$bus.$off('on:drop-menu:display');
    } catch (err) {
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/planning/_schedule-playground-list.scss";

.scroll-x {
  overflow-x: scroll;
  overflow-y: hidden;
}
</style>
